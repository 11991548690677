import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers/index";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "Case",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
// Use configureStore to create the store
const store = configureStore({
  reducer: persistedReducer, // Pass the persisted reducer
});
// Create a persisted store
const persistedStore = persistStore(store);
export { store, persistedStore };
